import { createApp } from 'vue'
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import './polyfills'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import axios from 'axios'
import { apikey } from '../secret'

import sfConfig from '@/config'

const oktaAuth = new OktaAuth(sfConfig.oidc)

const app = createApp(App)
app.use(store).use(router).use(Vuex).use(OktaVue, { oktaAuth }).mount('#app');

app.config.globalProperties.lambdaUrl = 'https://ch47ttg4d3.execute-api.us-east-1.amazonaws.com/dev'
app.config.globalProperties.apikey = apikey; 
app.config.globalProperties.$http = axios;
app.config.globalProperties.pubWiseIssueLimit = sfConfig.publicationWiseIssueLimit;

